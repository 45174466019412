


























































































































































































































import Vue from "vue";
import { ResponsePagination } from "@/models/interface/common.interface";
import { userServices } from "@/services/user.services";
import {
  ResponseListOfRole,
  ResponseListOfMenus,
  CreateMenuRole,
} from "@/models/interface/user.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { getauthorities } from "@/helpers/cookie";
export default Vue.extend({
  data() {
    return {
      authPrivilege: [] as string[],
      loadingTable: false as boolean,
      dataDetail: "" as string,
      dataListRole: {} as ResponseListOfRole,
      dataListMenu: {} as ResponseListOfMenus,
      typemodal: "" as string,
      isFormSubmitted: false as boolean,
      selectedRowKeysModal: [] as number[],
      page: this.page || (1 as number),
      limit: this.limit || (10 as number),
      search: "" as string,
      sort: "name:asc" as string,
      trigerdisable: false as boolean,
      titlemodalOpen: "" as string,
      direction: "asc" as string,
      pageSizeSet: 10,
      payloadPagination: {},
      defaultPagination: false as boolean,
      modalOpen: false as boolean,
      form: this.$form.createForm(this, { name: "addMenuForm" }),
      tempMenuid: [] as string[],
      optionModal: [] as string[],
      columnsTable: [
        {
          title: this.$t("lbl_name"),
          dataIndex: "name",
          key: "name",
          sorter: true,
          width: 200,
        },
        {
          title: this.$t("lbl_description"),
          dataIndex: "description",
          key: "description",
          sorter: true,
          width: 200,
          scopedSlots: { customRender: "isNull" },
        },
      ] as any,
      columnsTableModal: [
        {
          title: this.$t("lbl_menu"),
          dataIndex: "menu",
          key: "menu",
          width: "100%",
          scopedSlots: { customRender: "menu" },
          responsiveColCheckBox: ["create", "read", "update", "delete"],
        },
        {
          title: this.$t("lbl_description"),
          dataIndex: "description",
          key: "description",
          width: "100%",
          sorter: true,
        },
        {
          title: this.$t("lbl_create"),
          dataIndex: "create",
          key: "create",
          width: "40%",
          scopedSlots: { customRender: "create" },
          align: "center",
        },
        {
          title: this.$t("lbl_read"),
          dataIndex: "read",
          key: "read",
          width: "40%",
          scopedSlots: { customRender: "read" },
          align: "center",
        },
        {
          title: this.$t("lbl_update"),
          dataIndex: "update",
          key: "update",
          width: "40%",
          scopedSlots: { customRender: "update" },
          align: "center",
        },
        {
          title: this.$t("lbl_delete"),
          dataIndex: "delete",
          key: "delete",
          width: "40%",
          scopedSlots: { customRender: "delete" },
          align: "center",
        },
      ],
      dataSourceModal: [] as CreateMenuRole[],
      formRules: {
        name: {
          label: this.$t("lbl_name"),
          name: "name",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "name",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        description: {
          label: this.$t("lbl_description"),
          name: "description",
          placeholder: this.$t("lbl_type_here"),
          decorator: ["description", {}],
        },
      },
    };
  },
  created() {
    const auth = getauthorities();
    auth.forEach((dataAuth) => {
      if (dataAuth?.name === "role") {
        this.authPrivilege = dataAuth.value;
        this.columnsTable.push({
          title: this.$t("lbl_action"),
          dataIndex: "operation",
          width: 120,
          align: "center",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
          button: dataAuth.actions,
        });
      }
    });
  },
  mounted() {
    this.getListOfRole("");
  },
  methods: {
    showConfirmation() {
      if (this.selectedRowKeysModal.length > 0) {
        this.$confirm({
          title: this.$t("lbl_modal_delete_title_confirm"),
          content: this.$t("lbl_modal_delete_info", {
            count: this.selectedRowKeysModal.length,
          }),
          onOk: () => {
            this.handleDeleteRow();
          },
          onCancel() {
            return;
          },
        });
      } else {
        this.$notification.error({
          message: this.$t("lbl_error_title") as string,
          description: this.$t("lbl_modal_delete_error_description") as string,
        });
      }
    },
    handleCLickFLoating() {
      this.createNew();
    },
    ResetFilter(getlist: boolean): void {
      this.direction = "";
      this.search = "";
      if (getlist) this.getListOfRole("");
    },
    reponseDeleteTable(response): void {
      const path = "/" + response.data.id;
      userServices.deleteRole(path).then((res) => {
        if (res) {
          this.ResetFilter(false);
          this.getListOfRole("");
        }
      });
    },
    reponseEditTable(response): void {
      this.trigerdisable = false;
      this.typemodal = "edit";
      this.titlemodalOpen = this.$t("lbl_edit_role").toString();
      this.ResetFilter(false);
      this.resetCheckbox();
      this.getListMenu("");
      this.getListOfRole("/" + response.data.id);
      this.dataDetail = "/" + response.data.id;
    },
    getListMenu(path): void {
      let params = {
        limit: 1000,
        page: 0,
      } as RequestQueryParamsModel;
      if (this.search) params.search = this.search;
      if (this.direction) params.sort = this.sort;
      userServices
        .listOfMenus(params, path)
        .then((res: ResponseListOfMenus) => {
          res.data.forEach((dataObject, index) => (dataObject.key = index));
          this.dataListMenu = res;
          this.columnsTableModal[0]["responsiveColSelect"] = [
            {
              name: "menu",
              width: "100%",
              placeholder: this.$t("lbl_title_list_menu"),
              options: res.data,
              value: "id",
            },
          ];
          this.modalOpen = true;
        });
    },
    handleInputTableModal(value, key, col) {
      console.log(value, key, col);
    },
    handleSelectTableModal(value, key, col, recordOptions): void {
      this.dataSourceModal[key] = {
        ...this.dataSourceModal[key],
        menu: value,
        create: recordOptions.find((data) => data.id === value).create,
        read: recordOptions.find((data) => data.id === value).read,
        update: recordOptions.find((data) => data.id === value).update,
        delete: recordOptions.find((data) => data.id === value).delete,
        id: value,
        disabledSelect: false,
        disabledCheckBox: true,
      };
      const option = recordOptions.find((data) => data.id === value);
      this.dataSourceModal[key] = {
        ...this.dataSourceModal[key],
        description: option?.description,
      };
      this.dataSourceModal = this.dataSourceModal.slice();
    },
    resetCheckbox(): void {
      this.selectedRowKeysModal = [];
    },
    onSelectChangeModal(selectedRowKeysModal) {
      this.selectedRowKeysModal = selectedRowKeysModal;
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      this.getListOfRole("");
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      this.getListOfRole("");
    },
    reponseSearchInput(response): void {
      if (response)
        this.search =
          "name~*" + response + "*_OR_description~" + response + "*";
      else this.search = "";
      this.getListOfRole("");
    },
    createNew(): void {
      this.resetCheckbox();
      this.form.resetFields();
      this.dataSourceModal = [];
      this.trigerdisable = false;
      this.typemodal = "create";
      this.titlemodalOpen = this.$t("lbl_create_new_role").toString();
      this.ResetFilter(false);
      this.getListMenu("");
    },

    handleCancel(): void {
      this.modalOpen = false;
    },
    onSorterChange(response): void {
      if (response.sort.order) {
        this.direction = response.sort.order === "ascend" ? "asc" : "desc";
        this.sort = `${response.sort.field}:${this.direction}`;
      } else {
        this.ResetFilter(false);
      }
      this.getListOfRole("");
    },
    getListOfRole(path: string): void {
      let params: any;
      if (!path) {
        params = {
          limit: this.limit,
          page: this.page - 1,
        } as RequestQueryParamsModel;
        if (this.search) params.search = this.search;
        if (this.direction) params.sorts = this.sort;
      }
      this.loadingTable = true;
      userServices
        .listOfRole(params, path)
        .then((res: any) => {
          if (!path) {
            res.data.forEach((dataObject, index) => (dataObject.key = index));
            this.dataListRole = res;
          } else {
            setTimeout(() => {
              this.form.setFieldsValue({
                name: res.name,
                description: res.description ? res.description : "-",
              });
            }, 200);
            res.menus.forEach(
              (dataObject, index) => (
                (dataObject.key = index),
                (dataObject.disabledCheckBox = true),
                (dataObject.disabledSelect = false),
                (dataObject.description = dataObject.description
                  ? dataObject.description
                  : "-"),
                (dataObject.menu = dataObject.name)
              )
            );
            this.dataSourceModal = res.menus;
          }
        })
        .finally(() => (this.loadingTable = false));
    },
    handleAddRow() {
      const dataColumn: CreateMenuRole = {
        menu: null,
        description: null,
        id: null,
        create: false,
        read: false,
        update: false,
        delete: false,
        key: this.dataSourceModal.length,
        disabledSelect: false,
        disabledCheckBox: false,
      };
      this.dataSourceModal = [...this.dataSourceModal, dataColumn];
    },
    handleDeleteRow() {
      this.dataSourceModal = this.dataSourceModal.filter((data) => {
        return !this.selectedRowKeysModal.includes(data.key);
      });
      this.dataSourceModal.forEach((data, index) => (data.key = index));
      this.selectedRowKeysModal = [];
    },
    createNewRole(datapost): void {
      userServices
        .createRole(datapost)
        .then((res) => {
          if (res) {
            this.modalOpen = false;
            this.getListOfRole("");
            this.form.resetFields();
            this.$message.success(this.$t("notif_create_success").toString());
          }
        })
        .finally(() => (this.isFormSubmitted = false));
    },
    updateRole(datapost): void {
      userServices
        .updateRole(datapost, this.dataDetail)
        .then((res) => {
          if (res) {
            this.modalOpen = false;
            this.getListOfRole("");
            this.form.resetFields();
            this.$message.success(this.$t("notif_update_success").toString());
          }
        })
        .finally(() => (this.isFormSubmitted = false));
    },
    submitForm(e, type: "create" | "edit"): void {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.tempMenuid = [];
          this.dataSourceModal.forEach((item) => {
            if (item.id) {
              this.tempMenuid.push(item.id);
            }
          });
          const checkduplicatedata = this.tempMenuid.some((element, index) => {
            return this.tempMenuid.indexOf(element) !== index;
          });
          if (!checkduplicatedata) {
            const datapost = {
              name: values.name,
              description: values.description,
              menuIds: this.tempMenuid,
            };
            this.isFormSubmitted = true;
            if (type === "create") {
              this.createNewRole(datapost);
            } else {
              this.updateRole(datapost);
            }
          } else {
            this.$notification.error({
              message: this.$t("lbl_error_title").toString(),
              description: this.$t("notif_error_duplicate_data").toString(),
            });
          }
        } else {
          this.$notification.error({
            message: this.$t("lbl_error_title").toString(),
            description: this.$t("lbl_form_is_mandatory").toString(),
          });
        }
      });
    },
  },
});
