// Core
import { AxiosError } from "axios";
import { HttpClient } from "./http.service";
// Class
import { Api } from "@/models/class/api.class";

// Interfaces
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import {
  ResponseListOfRole,
  ResponseListOfPrivilege,
  ResponseListOfMenus,
  ResponseListOfUsers,
  RequestAddUsers,
  RequestUpdateUsers,
  RequestChangePassword,
  ResponseDetailUserByAuth,
  RequestPrivilege,
  RequestMenu,
  RequestRole,
  RequestPrivilegeUpdate,
  ResponseDetailRole,
  ResponseDetailUser,
  ResponseDetailMenu,
  RequestAdminChangePassword,
} from "@/models/interface/user.interface";

export class UserServices extends HttpClient {
  listOfUsers = (
    params: RequestQueryParamsModel
  ): Promise<ResponseListOfUsers> => {
    return this.get<ResponseListOfUsers>(Api.Users, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  /**
   * @deprecated
   * use {@linkcode userServices.getMenus}
   */
  listOfMenus = (
    params: RequestQueryParamsModel,
    path: string
  ): Promise<ResponseListOfMenus> => {
    return this.get<ResponseListOfMenus>(Api.Menus + path, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  detailMenu = (path: string): Promise<ResponseDetailMenu> => {
    return this.get<ResponseDetailMenu>(`${Api.Menus}/${path}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  createMenu = (payload: RequestMenu): Promise<boolean> => {
    return this.post<boolean, RequestMenu>(Api.Menus, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  updateMenu = (payload: RequestMenu, path: string): Promise<boolean> => {
    return this.put<boolean, RequestMenu>(Api.Menus + path, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  deleteMenu = (path: string): Promise<any> => {
    return this.delete<any>(Api.Menus + path)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  listOfPrivilege = (
    params: RequestQueryParamsModel
  ): Promise<ResponseListOfPrivilege> => {
    return this.get<ResponseListOfPrivilege>(Api.Privilege, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  createPrivilege = (payload: RequestPrivilege): Promise<boolean> => {
    return this.post<boolean, RequestPrivilege>(Api.Privilege, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  updatePrivilege = (
    id: string,
    payload: RequestPrivilegeUpdate
  ): Promise<boolean> => {
    return this.put<boolean, RequestPrivilegeUpdate>(
      Api.Privilege + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  /**
   * @deprecated
   * use {@linkcode userServices.getRoles}
   */
  listOfRole = (
    params: RequestQueryParamsModel,
    path: string
  ): Promise<ResponseListOfRole> => {
    return this.get<ResponseListOfRole>(Api.Role + path, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  /**
   * @deprecated
   * use {@linkcode userServices.getDetailRole}
   */
  detailRole = (id: string): Promise<ResponseDetailRole> => {
    return this.get<ResponseDetailRole>(Api.Role + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  createRole = (payload: RequestRole): Promise<boolean> => {
    return this.post<boolean, RequestRole>(Api.Role, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  updateRole = (payload: RequestRole, path: string): Promise<boolean> => {
    return this.put<boolean, RequestRole>(Api.Role + path, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  deleteRole = (path: string): Promise<any> => {
    return this.delete<any>(Api.Role + path)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  /**
   * @deprecated
   * use {@linkcode userServices.create}
   */
  addUser = (payload: RequestAddUsers): Promise<string> => {
    return this.post<string, RequestAddUsers>(Api.Users, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  updateUser = (payload: RequestUpdateUsers, id: string): Promise<string> => {
    return this.put<string, RequestUpdateUsers>(Api.Users + `/${id}`, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  deleteUser = (id: string): Promise<boolean> => {
    return this.delete<boolean>(Api.Users + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  detailUser = (id: string): Promise<ResponseDetailUser> => {
    return this.get<ResponseDetailUser>(Api.Users + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  changePassword = (payload: RequestChangePassword): Promise<boolean> => {
    return this.put<boolean, RequestChangePassword>(Api.ChangePassword, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  adminChangePassword = (
    payload: RequestAdminChangePassword
  ): Promise<boolean> => {
    return this.put<boolean, RequestAdminChangePassword>(
      `${Api.ChangePassword}/admin`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  userDetailAuth = (): Promise<ResponseDetailUserByAuth> => {
    return this.get<ResponseDetailUserByAuth>(Api.UserDetailAuth)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
}
export const userServices = new UserServices();
